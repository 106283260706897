const DISPLAY_NONE_CLASS_NAME = 'display-none';
const IS_OPENED_CLASS_NAME = 'is-opened';

/**
 * Check if element is visible.
 *
 * @param element
 * @returns {boolean}
 */
function isVisible(element) {
    return !element.classList.contains(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Show element.
 * @param element
 */
function show(element) {
    element.classList.remove(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Hide element.
 *
 * @param element
 */
function hide(element) {
    element.classList.add(DISPLAY_NONE_CLASS_NAME);
}

/**
 * Expand (expendable) element.
 *
 * @param element
 */
function expand(element) {
    element.classList.add(IS_OPENED_CLASS_NAME);
}

/**
 *
 * Downsize (expandable) element.
 *
 * @param element
 */
function downsize(element) {
    element.classList.remove(IS_OPENED_CLASS_NAME);
}

module.exports = {
    isVisible,
    show,
    hide,
    expand,
    downsize
};