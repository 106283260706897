const declarePaymentPromise = require("../../../../api/declarePaymentPromise");
const recap = require("./recap");
const amountKnowledge = require("./amountKnowledge");
const amountSimulation = require("./amountSimulation");

const PAYMENT_METHOD_FORM_ID = 'pre-pay';
const AMOUNT_INPUT_ID = 'amount-school';

const SCHOOL_NAME_CLASS_NAME = 'school-name';
const RECAP_ELEMENT_ID = 'recap-title';

function setSchoolName(name) {
    const elements = document.getElementsByClassName(SCHOOL_NAME_CLASS_NAME);

    for(let element of elements) {
        element.innerText = name;
    }
}

function setAmount(amount) {
    const element = document.getElementById(AMOUNT_INPUT_ID);
    if(!element.disabled) {
        document.getElementById(AMOUNT_INPUT_ID).value = amount;
    }
}

function getAmount() {
    return document.getElementById(AMOUNT_INPUT_ID).value;
}

function disableFields(disableAmountKnowledgeFields, disabledAmountSimulationFields) {
    const paymentMethodFormElement = document.getElementById(PAYMENT_METHOD_FORM_ID);
    const elements = paymentMethodFormElement.querySelectorAll('input');

    for(let element of elements) {
        element.disabled = true;
    }

    // disable buttons
    const buttons = paymentMethodFormElement.getElementsByClassName('button');
    for(let button of buttons) {
        button.disabled = true;
    }

    disableAmountKnowledgeFields();
    disabledAmountSimulationFields();
}

function initEvents(args) {
    document.getElementById(PAYMENT_METHOD_FORM_ID).addEventListener('submit', e => {
        e.preventDefault();

        const amount = getAmount();

        if (!confirm(`Etes-vous certain de vouloir valider votre promesse de paiement ?`)) {
            return;
        }

        const storageKey = args.getCompanyInformationStorageKey();
        const paymentPromiseCompanyInformation = JSON.parse(localStorage.getItem(storageKey));

        // REMOVE PAYMENT TYPE
        const paymentPromise = {
            ...paymentPromiseCompanyInformation,
            amount,
            schoolCode: args.getSchoolCode()
        };

        declarePaymentPromise(paymentPromise)
            .then(request => {
                const response = JSON.parse(request.response);

                localStorage.removeItem(storageKey);
                localStorage.setItem(args.getPaymentPromiseIdStorageKey(), response.ids[0]);

                const schoolCode = args.getSchoolCode();
                // ALMOST USELESS
                recap.fillMissingValues(schoolCode);
                recap.show();
                recap.expand();
                disableFields(amountKnowledge.disableFields, amountSimulation.disableFields);
                recap.replacePaymentPromiseIds(response.ids);
                recap.scrollTo();
                args.openConfirmationModal();
            })
            .catch(request => {
                console.error(request);
                alert("La promesse de paiement n'a pas pu être enregistrée. Veuillez ré-essayer ultérieurement");
            });
    });
}

module.exports = {
    setAmount,
    getAmount,
    setSchoolName,
    disableFields,
    initEvents
}