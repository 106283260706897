const displayUtils = require('../../../utils/displayUtils');
const calculateTaxAmount = require('../../../../api/calculateTaxAmount');

const AMOUNT_SIMULATION_FORM_ID = 'tax-amount-calculation';
const EXPANDABLE_AMOUNT_SIMULATION_ELEMENT_ID = 'calculate';
const LESS_THAN_250_EMPLOYEES_RADIO_ID = 'less-than-250-emp';
const MORE_THAN_250_EMPLOYEES_RADIO_ID = 'more-than-250-emp';

const AMOUNT_CALCULATION_WRAPPER_ELEMENT_ID = 'amount-calculation';
const WAGE_BILL_INPUT_ID = 'wage-bill';
const DEDUCTION_INPUT_ID = 'deduction';
const DEDUCTION_CSA_INPUT_ID = 'deduction-csa';
const CALCULATED_AMOUNT_ELEMENT_ID = 'calculated-amount';

function calculateAmount(companySize, setAmount, setTotalTaxAmountBalance, clearReversibleCalculatorFields) {
    const wageBillAmount = document.getElementById(WAGE_BILL_INPUT_ID).value;

    let deductionAmount;
    if(LESS_THAN_250_EMPLOYEES_RADIO_ID === companySize) {
        deductionAmount = document.getElementById(DEDUCTION_INPUT_ID).value;
    } else {
        deductionAmount = document.getElementById(DEDUCTION_CSA_INPUT_ID).value;
    }

    if(!(wageBillAmount || deductionAmount)) {
        return;
    }

    calculateTaxAmount(parseFloat(wageBillAmount), parseFloat(deductionAmount))
        .then(request => {
            const result = JSON.parse(request.response);
            const taxAmount = result['tax_amount'];
            const calculatedAmountElement = document.getElementById(CALCULATED_AMOUNT_ELEMENT_ID);

            let displayAmount = 0;
            if(taxAmount > 0) {
                displayAmount = taxAmount;
            }

            calculatedAmountElement.innerText = String(displayAmount);
            setAmount(displayAmount);
            // setTotalTaxAmountBalance(displayAmount);
            // clearReversibleCalculatorFields();
            displayUtils.show(calculatedAmountElement.parentElement);
        });
}

function showAmountCalculationFields(companySize) {
    displayUtils.show(document.getElementById(AMOUNT_CALCULATION_WRAPPER_ELEMENT_ID));

    if(LESS_THAN_250_EMPLOYEES_RADIO_ID === companySize) {
        displayUtils.show(document.getElementById(DEDUCTION_INPUT_ID).parentElement);
        displayUtils.hide(document.getElementById(DEDUCTION_CSA_INPUT_ID).parentElement);

        return;
    }

    displayUtils.hide(document.getElementById(DEDUCTION_INPUT_ID).parentElement);
    displayUtils.show(document.getElementById(DEDUCTION_CSA_INPUT_ID).parentElement);
}

function hide() {
    displayUtils.hide(document.getElementById(AMOUNT_SIMULATION_FORM_ID));
}

function show() {
    displayUtils.show(document.getElementById(AMOUNT_SIMULATION_FORM_ID));
}

function expand() {
    displayUtils.expand(document.getElementById(EXPANDABLE_AMOUNT_SIMULATION_ELEMENT_ID));
}

function downsize() {
    displayUtils.downsize(document.getElementById(EXPANDABLE_AMOUNT_SIMULATION_ELEMENT_ID));
}

function disableFields() {
    const elements = document.getElementById(AMOUNT_SIMULATION_FORM_ID).querySelectorAll('input');
    for(let element of elements) {
        element.disabled = true;
    }
}

function initEvents(args) {
    document.getElementById(LESS_THAN_250_EMPLOYEES_RADIO_ID).addEventListener('change', e => {
        const value = e.target.value;

        showAmountCalculationFields(value);
    });

    document.getElementById(MORE_THAN_250_EMPLOYEES_RADIO_ID).addEventListener('change', e => {
        const value = e.target.value;

        showAmountCalculationFields(value);
    });

    const amountCalculationForm = document.getElementById(AMOUNT_SIMULATION_FORM_ID);
    const handleAmountCalculationForm = () => {
        if(!amountCalculationForm.checkValidity()) {
            return;
        }

        calculateAmount(document.getElementById(LESS_THAN_250_EMPLOYEES_RADIO_ID).checked ?
            document.getElementById(LESS_THAN_250_EMPLOYEES_RADIO_ID).value :
            document.getElementById(MORE_THAN_250_EMPLOYEES_RADIO_ID).value,
            args.setAmount,
            args.setTotalTaxAmountBalance,
            args.clearReversibleCalculatorFields
        );

        args.expandPaymentInformation();
    };
    amountCalculationForm.addEventListener('input', e => {
        handleAmountCalculationForm();
    });
}

module.exports = {
    hide,
    show,
    expand,
    downsize,
    initEvents,
    disableFields
};