const displayUtils = require('../../../utils/displayUtils');
const getSchoolPaymentInformation = require('../../../../api/getSchoolPaymentInformation');
const sendRecap = require('../../../../api/sendRecap');

const EXPANDABLE_CHECK_PAYMENT_ELEMENT_ID = 'recap-title';

const PAYMENT_PROMISE_ID_PATTERN = '%id_payment_promise%';
const ID_TO_REPLACE_CLASS_NAME = 'id-to-replace';
const SEND_RECAP_CLASS_NAME = 'send-recap';

const RECAP_SCROLL_TO_ELEMENT_ID = 'recap-scroll-to';

function expand() {
    displayUtils.expand(document.getElementById(EXPANDABLE_CHECK_PAYMENT_ELEMENT_ID));
}

function hide() {
    displayUtils.hide(document.getElementById(EXPANDABLE_CHECK_PAYMENT_ELEMENT_ID).parentElement);
}

function show() {
    displayUtils.show(document.getElementById(EXPANDABLE_CHECK_PAYMENT_ELEMENT_ID).parentElement);
}

function scrollTo() {
    document.getElementById(RECAP_SCROLL_TO_ELEMENT_ID).scrollIntoView({
        behavior: 'smooth'
    });
}

function fillMissingValues(schoolCode) {
    getSchoolPaymentInformation(schoolCode)
        .then(request => {
            const result = JSON.parse(request.response);

            // TO COMPLETE
        })
}

function replacePaymentPromiseIds(paymentPromiseIds) {
    const elements = document.getElementsByClassName(ID_TO_REPLACE_CLASS_NAME);

    let paymentPromiseId = paymentPromiseIds[0];
    for(let element of elements) {
        if(element.tagName === 'A') {
            const urlPattern = element.getAttribute('data-url-pattern');
            if(urlPattern) {
                if (paymentPromiseIds.length === 1) {
                    element.href = urlPattern.replace(encodeURIComponent(PAYMENT_PROMISE_ID_PATTERN), paymentPromiseId);
                } else {
                    element.href = urlPattern.replace(encodeURIComponent(PAYMENT_PROMISE_ID_PATTERN), "multi");
                    element.href += "?ids=" +  paymentPromiseIds.join(',');
                }
            }

            const paymentPromiseIdData = element.getAttribute('data-payment-promise-id');
            if(paymentPromiseIdData) {
                if (paymentPromiseIds.length === 1) {
                    element.setAttribute('data-payment-promise-id', paymentPromiseIdData.replace(PAYMENT_PROMISE_ID_PATTERN, paymentPromiseId));
                } else {
                    element.setAttribute('data-payment-promise-id', paymentPromiseIdData.replace(PAYMENT_PROMISE_ID_PATTERN, paymentPromiseIds));
                    const paymentPromiseModeData  = element.getAttribute('data-payment-promise-mode');
                    if(paymentPromiseModeData) {
                        element.setAttribute('data-payment-promise-mode', "multi");
                    }
                }
            }
        }
    }
}

function initEvents() {
    document.querySelectorAll('.' + SEND_RECAP_CLASS_NAME).forEach(element => {
        element.addEventListener('click', function(e) {
            e.preventDefault();

            if(!confirm('Etes-vous sûr de vouloir vous envoyer l\'intention de versement par mail ?')) {
                return;
            }

            const paymentPromiseMode = e.target.getAttribute('data-payment-promise-mode');
            const paymentPromiseId = e.target.getAttribute('data-payment-promise-id');

            sendRecap(paymentPromiseMode, paymentPromiseId)
                .then(function(request) {
                    alert('Email envoyé avec succès.');
                    e.target.disabled = true;
                })
                .catch(function(request) {
                    alert('L\'email n\'a pas pu être envoyé.');
                });
        });
    });
}

module.exports = {
    expand,
    hide,
    show,
    fillMissingValues,
    replacePaymentPromiseIds,
    initEvents,
    scrollTo
}
