const amountSimulation = require('./amountSimulation');

const AMOUNT_KNOWLEDGE_FORM_ID = 'amount-knowledge';
const YES_I_KNOW_RADIO_ID = 'yes-i-know-amount';
const NO_I_DO_NOT_KNOW_RADIO_ID = 'no-i-do-not-know-amount';

function disableFields() {
    const elements = document.getElementById(AMOUNT_KNOWLEDGE_FORM_ID).querySelectorAll('input');
    for(let element of elements) {
        element.disabled = true;
    }
}

function initEvents(args) {
    document.getElementById(YES_I_KNOW_RADIO_ID).addEventListener('change', e => {
        amountSimulation.downsize();
        args.expandPaymentInformation();
        args.expandConvertBalanceIntoPercentage();
    });

    document.getElementById(NO_I_DO_NOT_KNOW_RADIO_ID).addEventListener('change', e => {
        amountSimulation.expand();
        args.expandConvertBalanceIntoPercentage();
    });

    window.onload = () => {
        if(document.getElementById(YES_I_KNOW_RADIO_ID).checked) {
            args.expandConvertBalanceIntoPercentage();
        }

        if(document.getElementById(NO_I_DO_NOT_KNOW_RADIO_ID).checked) {
            args.expandConvertBalanceIntoPercentage();
        }
    };
}

module.exports = {
    initEvents,
    disableFields
}