const requestHelper = require('./requestHelper');

module.exports = (code) => {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();

        req.addEventListener('load', function() {
            requestHelper.routePromise(req, resolve, reject);
        });

        req.addEventListener('error', function() {
            reject(req);
        });

        req.open('GET', window.location.origin + `/api/school/${code}/payment-information`);
        req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        req.send();
    });
};