function initEvents() {
    const buttons = document.querySelectorAll('.btn');
    const contents = document.querySelectorAll('.hidden-area');
    buttons.forEach(button => {
        button.addEventListener('click', (e) => {
            let siblingClassList = button.nextElementSibling.classList;
            if (siblingClassList.contains('active')) {
                siblingClassList.remove('active');
            }else{
                contents.forEach(content => {
                    content.classList.remove('active');
                });
                siblingClassList.add('active');
            }
        });
    });


    const subButtons = document.querySelectorAll('.sub-btn');
    const subContents = document.querySelectorAll('.hidden-sub-area');
    subButtons.forEach(button => {
        button.addEventListener('click', (e) => {
            let siblingClassList = button.nextElementSibling.classList;
            if (siblingClassList.contains('active')) {
                siblingClassList.remove('active');
            }else{
                subContents.forEach(content => {
                    content.classList.remove('active');
                });
                siblingClassList.add('active');
            }
        });
    });
}

module.exports = {
    initEvents
};