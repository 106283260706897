const displayUtils = require('../../../utils/displayUtils');
const declarationTypeChoice = require('../declarationTypeChoice');

const CAMPUS_CHOICE_ELEMENT_ID = 'campus-choice';
const ILE_DE_FRANCE_CAMPUS_RADIO_ID = 'choix-campus-radio--ile-de-france';
const NOUVELLE_AQUITAINE_CAMPUS_RADIO_ID = 'choix-campus-radio--nouvelle-aquitaine';
const AUVERGNE_RHONE_ALPES_CAMPUS_RADIO_ID = 'choix-campus-radio--auvergne-rhone-alpes';
const CAMPUS_CHOICE_ITEM_CLASS_NAME = 'campus-choice-item';

const ILE_DE_FRANCE_CAMPUS = 'ile-de-france';
const NOUVELLE_AQUITAINE_CAMPUS = 'nouvelle_aquitaine';
const AUVERGNE_RHONE_ALPES_CAMPUS = 'auvergne-rhone-alpes';

const INSEEC_PARIS_SCHOOL_NAME = 'INSEEC Paris';
const INSEEC_LYON_SCHOOL_NAME = 'INSEEC Lyon';
const INSEEC_BORDEAUX_SCHOOL_NAME = 'INSEEC Bordeaux';

function getSelectedCampus() {
    const ileDeFranceCampusRadio = document.getElementById(ILE_DE_FRANCE_CAMPUS_RADIO_ID);
    if(ileDeFranceCampusRadio.checked) {
        return ILE_DE_FRANCE_CAMPUS;
    }

    const nouvelleAquitaineCampusRadio = document.getElementById(NOUVELLE_AQUITAINE_CAMPUS_RADIO_ID);
    if(nouvelleAquitaineCampusRadio.checked) {
        return NOUVELLE_AQUITAINE_CAMPUS;
    }

    const auvergneRhoneAlpesCampusRadio = document.getElementById(AUVERGNE_RHONE_ALPES_CAMPUS_RADIO_ID);
    if(auvergneRhoneAlpesCampusRadio.checked) {
        return AUVERGNE_RHONE_ALPES_CAMPUS;
    }

    return null;
}

function show() {
    displayUtils.show(document.getElementById(CAMPUS_CHOICE_ELEMENT_ID));
}

function hide() {
    displayUtils.hide(document.getElementById(CAMPUS_CHOICE_ELEMENT_ID));
}

function isVisible() {
    return displayUtils.isVisible(document.getElementById(CAMPUS_CHOICE_ELEMENT_ID));
}

function disableFields() {
    if(!document.getElementById(CAMPUS_CHOICE_ELEMENT_ID)) {
        return;
    }

    document.getElementById(ILE_DE_FRANCE_CAMPUS_RADIO_ID).disabled = true;
    document.getElementById(NOUVELLE_AQUITAINE_CAMPUS_RADIO_ID).disabled = true;
    document.getElementById(AUVERGNE_RHONE_ALPES_CAMPUS_RADIO_ID).disabled = true;
}

function uncheckSelectedCampus() {
    const items = document.getElementsByClassName(CAMPUS_CHOICE_ITEM_CLASS_NAME);
    for(let item of items) {
        item.checked = false;
    }
}

function scrollTo() {
    document.getElementById('choix-campus-radio--ile-de-france').scrollIntoView({
        behavior: 'smooth'
    });
}

function initEvents(args) {
    const items = document.getElementsByClassName(CAMPUS_CHOICE_ITEM_CLASS_NAME);
    for(let item of items) {
        item.addEventListener('change', e => {
            const elementId = e.target.id;
            const value = e.target.value;

            switch(elementId) {
                case ILE_DE_FRANCE_CAMPUS_RADIO_ID:
                    args.setSchoolName(INSEEC_PARIS_SCHOOL_NAME);
                    break;
                case NOUVELLE_AQUITAINE_CAMPUS_RADIO_ID:
                    args.setSchoolName(INSEEC_BORDEAUX_SCHOOL_NAME);
                    break;
                case AUVERGNE_RHONE_ALPES_CAMPUS_RADIO_ID:
                    args.setSchoolName(INSEEC_LYON_SCHOOL_NAME);
                    break;
                default:
            }

            args.setTechnicalSheetAssetRelPath();
            args.showInformationalTechnicalSheet();
            args.showOnlyContact(value);
            declarationTypeChoice.show();
            args.scrollToDeclarationTypeChoice();
        });
    }
}

module.exports = {
    hide,
    show,
    isVisible,
    getSelectedCampus,
    ILE_DE_FRANCE_CAMPUS,
    NOUVELLE_AQUITAINE_CAMPUS,
    AUVERGNE_RHONE_ALPES_CAMPUS,
    disableFields,
    uncheckSelectedCampus,
    scrollTo,
    initEvents
}