const schoolChoice = require('./js/front/declarePaymentPromise/components/schoolChoice');
const campusChoice = require('./js/front/declarePaymentPromise/components/inseec/campusChoice');
const declarePaymentPromise = require('./js/front/declarePaymentPromise/declarePaymentPromise');

const PAPER_FORM_ELEMENT_ID = 'download-paper-form';
const ONLINE_FORM_ELEMENT_ID = 'declaration-type--online';
const DECISION_MAKER_EMAIL_ELEMENT_ID = 'contact_email';
const PRE_PAY_FORM_ID = 'pre-pay';
const COMPANY_INFORMATON_FORM_ID = 'company-information';

/****************************************************************************************************************************
 * Objects "window.abtasty", "dataLayer" and function "EA_push" depend on Google Tag Manager configuration and integration. *
 ****************************************************************************************************************************/

/**
 * Resolve event value.
 *
 * @param initialEventValue
 * @returns {string|*}
 */
function resolveEventValue(initialEventValue) {
    // domainCode can be : omnes, inseec, ebs or ece
    const domainCode = declarePaymentPromise.getDomainCode();

    if(domainCode === declarePaymentPromise.OMNES_GROUP) {
        // schoolCode can be : inseec, ebs or ece
        const schoolCode = schoolChoice.getSelectedSchool();

        switch(schoolCode) {
            case schoolChoice.INSEEC_GROUP:
                return buildSchoolIdentifiedEventValue(initialEventValue, 'INSEEC', '-');
            case schoolChoice.ECE_SCHOOL:
                return buildSchoolIdentifiedEventValue(initialEventValue, 'ECE', '-');
            case schoolChoice.EBS_SCHOOL:
                return buildSchoolIdentifiedEventValue(initialEventValue, 'EBS', '-');
        }
    }

    return initialEventValue;
}

/**
 * Build school identified event value.
 *
 * Example : "TA-FORM" becomes "TA-INSEEC-FORM" with "TA-FORM" as eventValue, "INSEEC" as schoolId and "-" as delimiter
 *
 * @param eventValue
 * @param schoolId
 * @param delimiter
 * @returns {*}
 */
function buildSchoolIdentifiedEventValue(eventValue, schoolId, delimiter) {
    const split = eventValue.split(delimiter);

    split.splice(1, 0, schoolId);

    return split.join(delimiter);
}

function getSchoolIdentifier() {
    const domainCode = declarePaymentPromise.getDomainCode();

    let schoolCode = domainCode.toUpperCase();
    let campusCode = null;
    if(domainCode === declarePaymentPromise.OMNES_GROUP) {
        const selectedSchool = schoolChoice.getSelectedSchool();

        switch(selectedSchool) {
            case schoolChoice.INSEEC_GROUP:
                schoolCode = 'INSEEC';
                switch(campusChoice.getSelectedCampus()) {
                    case campusChoice.ILE_DE_FRANCE_CAMPUS:
                        campusCode = 'PARIS';
                        break;
                    case campusChoice.NOUVELLE_AQUITAINE_CAMPUS:
                        campusCode = 'BORDEAUX';
                        break;
                    case campusChoice.AUVERGNE_RHONE_ALPES_CAMPUS:
                        campusCode = 'LYON';
                        break;
                }
                break;
            case schoolChoice.ECE_SCHOOL:
                schoolCode = 'ECE';
                break;
            case schoolChoice.EBS_SCHOOL:
                schoolCode = 'EBS';
                break;
        }
    }

    if(domainCode === schoolChoice.INSEEC_GROUP) {
        switch(campusChoice.getSelectedCampus()) {
            case campusChoice.ILE_DE_FRANCE_CAMPUS:
                campusCode = 'PARIS';
                break;
            case campusChoice.NOUVELLE_AQUITAINE_CAMPUS:
                campusCode = 'BORDEAUX';
                break;
            case campusChoice.AUVERGNE_RHONE_ALPES_CAMPUS:
                campusCode = 'LYON';
                break;
        }
    }

    return {
        schoolCode,
        campusCode
    };
}

/**
 * Digest message.
 *
 * @param message
 * @returns {Promise<ArrayBuffer>}
 */
function digestMessage(message) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);

    return window.crypto.subtle.digest('SHA-256', data);
}

/**
 * Get hex string.
 *
 * @param buffer
 * @returns {string}
 */
function hexString(buffer) {
    const byteArray = new Uint8Array(buffer);
    const hexCodes = [...byteArray].map(value => {
        const hexCode = value.toString(16);

        return hexCode.padStart(2, '0');
    });

    return hexCodes.join('');
}

/**
 * Get email.
 *
 * @returns {*}
 */
function getEmail() {
    return document.getElementById(DECISION_MAKER_EMAIL_ELEMENT_ID).value;
}

/**
 * Trigger Eulerian tag.
 *
 * @param date
 * @param email
 * @param eventValue
 * @param schoolCode
 * @param campus
 */
function triggerEulerianTag(date, email, eventValue, schoolCode, campus = null) {
    if(!date || !eventValue || !schoolCode) {
        if(debugTracking) {
            console.debug('Missing Eulerian args');
            console.debug('Date :', date, 'Event value :', eventValue, 'School code :', schoolCode);
        }

        return;
    }

    if(email) {
        digestMessage(email).then(digestValue => {
            const hash = hexString(digestValue);

            const payload = [
                'uid', hash,
                'ref', date,
                'estimate', '1',
                'type', eventValue,
                'path', window.location.pathname,
                'pagegroup', 'FORMULAIRE',
                'ville-ecole', null,
                'ville-jpo', null,
                'campus', campus,
                'niveau-entree', null,
                'annee-entree', null,
                'date-rentree', null,
                'choix-programme', schoolCode,
                'source', 'FORMULAIRE TAXE D’APPRENTISSAGE'
            ];

            if(debugTracking) {
                console.log('-------- Eulerian avec email --------');
                console.log(...payload);

                return;
            }

            EA_push(...payload);
        });

        return;
    }

    const uid = Math.floor(Math.random() * 99999999999) + 1;
    const payload = [
        'uid', uid,
        'ref', date,
        'estimate', '1',
        'type', eventValue,
        'path', window.location.pathname,
        'pagegroup', 'FORMULAIRE',
        'ville-ecole', null,
        'ville-jpo', null,
        'campus', campus,
        'niveau-entree', null,
        'annee-entree', null,
        'date-rentree', null,
        'choix-programme', schoolCode,
        'source', 'FORMULAIRE TAXE D’APPRENTISSAGE'
    ];

    if(debugTracking) {
        console.log('-------- Eulerian sans email --------');
        console.log(...payload);

        return;
    }

    EA_push(...payload);
}

/**
 * Trigger AB Tasty tag.
 *
 * @param timestamp
 * @param event
 */
function triggerAbTastyTag(timestamp, event) {
    const payload = {
        tid: String(timestamp),
        ta: "Lead generation - " + event,
        tr: 1,
        tc: "EUR",
        icn: 1
    };

    if(debugTracking) {
        console.log('-------- AB Tasty --------');
        console.log(payload);

        return;
    }

    window.abtasty.send("transaction", payload);
}

/**
 * Trigger google tag manager tag.
 *
 * @param date
 * @param eventValue
 * @param email
 * @param schoolCode
 * @param campus
 */
function triggerGoogleTagManagerTag(date, email, eventValue, schoolCode, campus = null) {
    if(!date || !eventValue || !schoolCode) {
        if(debugTracking) {
            console.debug('Missing GTM args');
            console.debug('Date :', date, 'Event value :', eventValue, 'School code :', schoolCode);
        }

        return;
    }

    const payload = {
        'event': 'conversion',
        'reference' : date,
        'mail' : email || null,
        'path' : window.location.pathname,
        'formCategory' : eventValue,
        'program': schoolCode,
        'campus' : campus,
        'source' : 'FORMULAIRE TAXE D’APPRENTISSAGE'
    };

    if(debugTracking) {
        console.log('-------- GTM --------');
        console.log(payload);

        return;
    }

    dataLayer.push(payload);
}

/**
 * Init events on declaration type clicking.
 */
function clickDeclarationType() {
    const paperForm = document.getElementById(PAPER_FORM_ELEMENT_ID);
    if(paperForm) {
        paperForm.addEventListener('click', e => {
            const email = getEmail();
            const eventValue = 'TA-PDF';
            const resolvedEventValue = resolveEventValue(eventValue);
            const schoolIdentifier = getSchoolIdentifier();
            const date = Date.now();

            triggerGoogleTagManagerTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
            triggerEulerianTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
            triggerAbTastyTag(date, resolvedEventValue);
        });
    }

    document.getElementById(ONLINE_FORM_ELEMENT_ID).addEventListener('click', e => {
        const email = getEmail();
        const eventValue = 'TA-FORM';
        const resolvedEventValue = resolveEventValue(eventValue);
        const schoolIdentifier = getSchoolIdentifier();
        const date = Date.now();

        triggerGoogleTagManagerTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerEulerianTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerAbTastyTag(date, resolvedEventValue);
    });
}

function submitCompanyInformation() {
    document.getElementById(COMPANY_INFORMATON_FORM_ID).addEventListener('submit', e => {
        const email = getEmail();
        const date = Date.now();

        let eventValue = 'TA-INFO';
        const resolvedEventValue = resolveEventValue(eventValue);
        const schoolIdentifier = getSchoolIdentifier();

        triggerGoogleTagManagerTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerEulerianTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerAbTastyTag(date, resolvedEventValue);
    });
}

/**
 * Init events on payment promise submitting.
 */
function submitPaymentPromise() {
    document.getElementById(PRE_PAY_FORM_ID).addEventListener('submit', e => {
        const email = getEmail();
        const date = Date.now();

        const eventValue = 'TA-FORM-CHEQUE';
        const resolvedEventValue = resolveEventValue(eventValue);
        const schoolIdentifier = getSchoolIdentifier();

        triggerGoogleTagManagerTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerEulerianTag(date, email, eventValue, schoolIdentifier['schoolCode'], schoolIdentifier['campusCode']);
        triggerAbTastyTag(date, resolvedEventValue);
    });
}

function initEvents() {
    clickDeclarationType();
    submitCompanyInformation();
}

initEvents();