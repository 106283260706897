const displayUtils = require('../../../utils/displayUtils');
const amountKnowledge = require('./amountKnowledge');
const amountSimulation = require('./amountSimulation');
const prefill = require('./prefill');
const receiptRecipients = require('./receiptRecipients');
const amountValidation = require('./amountValidation');

const PAYMENT_PROMISE_WRAPPER_ELEMENT_ID = 'payment-promise';
const COMPANY_INFORMATION_FORM_ID = 'company-information';
const PAYMENT_INFORMATION_WRAPPER_ELEMENT_ID = 'payment-information';
const EXPANDABLE_PAYMENT_INFORMATION_ELEMENT_ID = 'pay';
const CONVERT_BALANCE_INTO_PERCENTAGE = 'convert-balance-into-percentage';

const SIRET_INPUT_ID = 'siret';

const COMPANY_NAME_INPUT_ID = 'social';
const COMPANY_ADDRESS_INPUT_ID = 'address';
const COMPANY_POSTBOX_INPUT_ID = 'postbox';
const COMPANY_POSTCODE_INPUT_ID = 'postal_code';
const COMPANY_CITY_INPUT_ID = 'town';
const COMPANY_COUNTRY_INPUT_ID = 'country';
const COMPANY_PARENT_INPUT_ID = 'parent';

const NOTES_TEXTAREA_ID = 'remarques';

const DECISION_MAKER_MADAM_TITLE_RADIO_ID = 'contact_madame';
const DECISION_MAKER_MISTER_TITLE_RADIO_ID = 'contact_monsieur';
const DECISION_MAKER_LAST_NAME_INPUT_ID = 'contact_name';
const DECISION_MAKER_FIRST_NAME_INPUT_ID = 'contact_forname';
const DECISION_MAKER_EMAIL_INPUT_ID = 'contact_email';
const DECISION_MAKER_PHONE_INPUT_ID = 'contact_phone';
const DECISION_MAKER_FUNCTION_INPUT_ID = 'contact_function';

const DECISION_MAKER_IS_RECEIPT_RECIPIENT_CHECKBOX_ID = 'contact';

const FORMER_STUDENT_CLASS_INPUT_ID = 'student-class';
const STUDENT_PARENT_NAME_INPUT_ID = 'student-name';
const HOST_COMPANY_CHECKBOX_ID = 'host-company';
const OTHER_NAME_INPUT_ID = 'provider-name';

function getCompanyInformationStorageKey() {
    return 'company_information';
}

function getPaymentPromiseIdStorageKey() {
    return 'payment_promise_id';
}

/**
 * Populate payment promise company information.
 */
function populatePaymentPromiseCompanyInformation() {
    const siret = document.getElementById(SIRET_INPUT_ID).value;
    const company = {};
    company['name'] = document.getElementById(COMPANY_NAME_INPUT_ID).value;
    company['address'] = document.getElementById(COMPANY_ADDRESS_INPUT_ID).value;
    company['postBox'] = document.getElementById(COMPANY_POSTBOX_INPUT_ID).value;
    company['postalCode'] = document.getElementById(COMPANY_POSTCODE_INPUT_ID).value;
    company['city'] = document.getElementById(COMPANY_CITY_INPUT_ID).value;
    company['parent'] = document.getElementById(COMPANY_PARENT_INPUT_ID).value;

    const decisionMaker = {};
    decisionMaker['title'] = getDecisionMakerTitle();
    decisionMaker['lastName'] = document.getElementById(DECISION_MAKER_LAST_NAME_INPUT_ID).value;
    decisionMaker['firstName'] = document.getElementById(DECISION_MAKER_FIRST_NAME_INPUT_ID).value;
    decisionMaker['function'] = document.getElementById(DECISION_MAKER_FUNCTION_INPUT_ID).value;
    decisionMaker['email'] = document.getElementById(DECISION_MAKER_EMAIL_INPUT_ID).value;
    decisionMaker['phoneNumber'] = document.getElementById(DECISION_MAKER_PHONE_INPUT_ID).value;
    const notes = document.getElementById(NOTES_TEXTAREA_ID).value;

    const isDecisionMakerTheReceiptRecipient = document.getElementById(DECISION_MAKER_IS_RECEIPT_RECIPIENT_CHECKBOX_ID).checked;

    let receiptRecipientsList = [];
    if(isDecisionMakerTheReceiptRecipient) {
        receiptRecipientsList.push({ person : { ...decisionMaker }});
    } else {
        receiptRecipientsList.push(receiptRecipients.getValues(1));

        if(receiptRecipients.isVisible(2)) {
            receiptRecipientsList.push(receiptRecipients.getValues(2));
        }

        if(receiptRecipients.isVisible(3)) {
            receiptRecipientsList.push(receiptRecipients.getValues(3));
        }
    }

    const supportOrigin = {
        formerStudentClass: document.getElementById(FORMER_STUDENT_CLASS_INPUT_ID).value,
        studentParentName: document.getElementById(STUDENT_PARENT_NAME_INPUT_ID).value,
        hostCompany: document.getElementById(HOST_COMPANY_CHECKBOX_ID).checked,
        other: document.getElementById(OTHER_NAME_INPUT_ID).value
    };

    return {
        siret,
        company,
        decisionMaker,
        notes,
        receiptRecipients: receiptRecipientsList,
        supportOrigin
    };
}

function disableCompanyInformationFields(disableSchoolChoiceFields, disableCampusChoiceFields) {
    const paymentPromiseForm = document.getElementById(COMPANY_INFORMATION_FORM_ID);
    const inputs = paymentPromiseForm.getElementsByTagName('input');

    // disable inputs
    for(let input of inputs) {
        input.disabled = true;
    }

    // disable buttons
    const buttons = paymentPromiseForm.getElementsByClassName('button');
    for(let button of buttons) {
        button.disabled = true;
    }

    // disable textarea
    document.getElementById(NOTES_TEXTAREA_ID).disabled = true;

    disableSchoolChoiceFields();
    disableCampusChoiceFields();
}

function populateCompanyInformations(companyInformations) {
    document.getElementById(SIRET_INPUT_ID).value = companyInformations.siret;
    document.getElementById(COMPANY_NAME_INPUT_ID).value = companyInformations.company.name;
    document.getElementById(COMPANY_ADDRESS_INPUT_ID).value = companyInformations.company.address;
    document.getElementById(COMPANY_POSTCODE_INPUT_ID).value = companyInformations.company.postCode;
    document.getElementById(COMPANY_CITY_INPUT_ID).value = companyInformations.company.city;
    document.getElementById(COMPANY_COUNTRY_INPUT_ID).value = companyInformations.company.country;
}

function getDecisionMakerTitle() {
    const decisionMakerMadamTitleElement = document.getElementById(DECISION_MAKER_MADAM_TITLE_RADIO_ID);
    const decisionMakerMisterTitleElement = document.getElementById(DECISION_MAKER_MISTER_TITLE_RADIO_ID);

    if(decisionMakerMadamTitleElement.checked) {
        return decisionMakerMadamTitleElement.value;
    }

    if(decisionMakerMisterTitleElement.checked) {
        return decisionMakerMisterTitleElement.value;
    }

    return null;
}

function hide() {
    displayUtils.hide(document.getElementById(PAYMENT_PROMISE_WRAPPER_ELEMENT_ID));
}

function show() {
    displayUtils.show(document.getElementById(PAYMENT_PROMISE_WRAPPER_ELEMENT_ID));
}

function showPaymentInformation() {
    displayUtils.show(document.getElementById(PAYMENT_INFORMATION_WRAPPER_ELEMENT_ID));
}

function expandPaymentInformation() {
    displayUtils.expand(document.getElementById(EXPANDABLE_PAYMENT_INFORMATION_ELEMENT_ID));
}

function expandConvertBalanceIntoPercentage() {
    displayUtils.expand(document.getElementById(CONVERT_BALANCE_INTO_PERCENTAGE));
}

function scrollToAmountKnowledge() {
    document.getElementById('amount-knowledge').scrollIntoView({
        behavior: "smooth"
    });
}

function scrollToSiret() {
    document.getElementById('payment-promise').scrollIntoView({
        behavior: "smooth"
    });
}

function initEvents(args) {
    amountKnowledge.initEvents({ expandPaymentInformation, expandConvertBalanceIntoPercentage });
    amountSimulation.initEvents({ setAmount: amountValidation.setAmount, expandPaymentInformation, setTotalTaxAmountBalance: args.setTotalTaxAmountBalance, clearReversibleCalculatorFields: args.clearReversibleCalculatorFields });
    prefill.initEvents({ populateCompanyInformations });
    receiptRecipients.initEvents();

    document.getElementById(COMPANY_INFORMATION_FORM_ID).addEventListener('submit', e => {
        e.preventDefault();
        const paymentPromise = populatePaymentPromiseCompanyInformation();

        localStorage.setItem(getCompanyInformationStorageKey(), JSON.stringify(paymentPromise));
        showPaymentInformation();
        disableCompanyInformationFields(args.disableSchoolChoiceFields, args.disableCampusChoiceFields);
        scrollToAmountKnowledge();
    });

    amountValidation.initEvents({
        getCompanyInformationStorageKey,
        getPaymentPromiseIdStorageKey,
        getSchoolCode: args.getSchoolCode,
        openConfirmationModal: args.openConfirmationModal
    })
}

module.exports = {
    hide,
    show,
    setSchoolName: amountValidation.setSchoolName,
    getPaymentPromiseIdStorageKey,
    scrollToSiret,
    initEvents
}