const displayUtils = require('../../utils/displayUtils');
const campusChoice = require('./inseec/campusChoice');
const paymentPromise = require('./paymentPromise/paymentPromise');
const declarationTypeChoice = require('./declarationTypeChoice');

const SCHOOL_CHOICE_ELEMENT_ID = 'school-choice';

const ECE_SCHOOL = 'ece';
const EBS_SCHOOL = 'ebs';
const INSEEC_GROUP = 'inseec';

const EBS_SCHOOL_RADIO_ID = 'choix-ecole--ebs';
const ECE_SCHOOL_RADIO_ID = 'choix-ecole--ece';
const INSEEC_GROUP_RADIO_ID = 'choix-ecole--inseec';
const SCHOOL_CHOICE_ITEM_CLASS_NAME = 'school-choice-item';

const ECE_SCHOOL_NAME = 'ECE Paris';
const EBS_SCHOOL_NAME = 'EBS Paris';

function getSelectedSchool() {
    const ebsSchoolRadio = document.getElementById(EBS_SCHOOL_RADIO_ID);
    if(ebsSchoolRadio.checked) {
        return EBS_SCHOOL;
    }

    const eceSchoolRadio = document.getElementById(ECE_SCHOOL_RADIO_ID);
    if(eceSchoolRadio.checked) {
        return ECE_SCHOOL;
    }

    const inseecSchoolRadio = document.getElementById(INSEEC_GROUP_RADIO_ID);
    if(inseecSchoolRadio.checked) {
        return INSEEC_GROUP;
    }

    return null;
}

function hide() {
    displayUtils.hide(document.getElementById(SCHOOL_CHOICE_ELEMENT_ID));
}

function show() {
    displayUtils.show(document.getElementById(SCHOOL_CHOICE_ELEMENT_ID));
}

function disableFields() {
    if(!document.getElementById(SCHOOL_CHOICE_ELEMENT_ID)) {
        return;
    }

    document.getElementById(EBS_SCHOOL_RADIO_ID).disabled = true;
    document.getElementById(ECE_SCHOOL_RADIO_ID).disabled = true;
    document.getElementById(INSEEC_GROUP_RADIO_ID).disabled = true;
}

function initEvents(args) {
    const items = document.getElementsByClassName(SCHOOL_CHOICE_ITEM_CLASS_NAME);
    for(let item of items) {
        item.addEventListener('change', e => {
            const elementId = e.target.id;

            if(INSEEC_GROUP_RADIO_ID === elementId) {
                campusChoice.show();
                campusChoice.uncheckSelectedCampus();
                paymentPromise.hide();
                declarationTypeChoice.uncheckOnlineDeclarationType();
                declarationTypeChoice.hide();
                args.hideInformationTechnicalSheet();
                args.scrollToCampusChoice();

                return;
            }

            if(campusChoice.isVisible()) {
                campusChoice.hide();
            }

            switch(elementId) {
                case ECE_SCHOOL_RADIO_ID:
                    args.setSchoolName(ECE_SCHOOL_NAME);
                    args.setTechnicalSheetAssetRelPath();
                    break;
                case EBS_SCHOOL_RADIO_ID:
                    args.setSchoolName(EBS_SCHOOL_NAME);
                    args.setTechnicalSheetAssetRelPath();
                    break;
                default:
            }

            args.showInformationalTechnicalSheet();
            declarationTypeChoice.show();
            args.scrollToDeclarationTypeChoice();
        });
    }
}

module.exports = {
    hide,
    show,
    initEvents,
    getSelectedSchool,
    ECE_SCHOOL,
    EBS_SCHOOL,
    INSEEC_GROUP,
    disableFields
};