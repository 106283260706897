require('select2');
require('select2/dist/js/i18n/fr.js');

const PREFILL_INPUT_ID = 'prefill';
const SEARCH_SIRET_ELEMENT_ID = 'search-siret';
const SIRENE_API_URL = 'https://api.insee.fr/entreprises/sirene/V3';
const TOKEN = 'd883a9aa-97aa-3298-9033-accd7c84e50b';

function initEvents(args) {
    $(document).ready(function() {
        $('#' + SEARCH_SIRET_ELEMENT_ID).select2({
            language: "fr",
            minimumInputLength: 14,
            maximumInputLength: 14,
            ajax: {
                url: SIRENE_API_URL + '/siret',
                data: function(params) {
                    return {
                        q: 'siret:' + params.term
                    };
                },
                headers: {
                    "Content-Type" : "application/json",
                    "Authorization": "Bearer " + TOKEN
                },
                dataType: 'json',
                method: 'GET',
                processResults: function (data) {
                    if(!data.header.nombre) {
                        return {results: []};
                    }

                    return {
                        results: data.etablissements.map(establishment => {
                            localStorage.setItem(establishment.siret, JSON.stringify(establishment));

                            return {
                                id: establishment.siret,
                                text: establishment.uniteLegale.denominationUniteLegale
                            };
                        })
                    };
                }
            }
        });
    });

    $('#' + SEARCH_SIRET_ELEMENT_ID).on('select2:select', function(e) {
        const item = localStorage.getItem(e.target.value);

        if(!item) {
            return;
        }

        const establishment = JSON.parse(item);

        const establishmentAddress = establishment.adresseEtablissement
        args.populateCompanyInformations({
            siret: establishment.siret,
            company: {
                name: establishment.uniteLegale.denominationUniteLegale,
                address: establishmentAddress.numeroVoieEtablissement + ', ' + establishmentAddress.typeVoieEtablissement + ' ' + establishmentAddress.libelleVoieEtablissement,
                city: establishmentAddress.libelleCommuneEtablissement,
                postCode: establishmentAddress.codePostalEtablissement,
                country: 'France'
            }
        });
    });
}

module.exports = {
    initEvents
};