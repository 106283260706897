const paymentPromise = require("./paymentPromise/paymentPromise");
const displayUtils = require("../../utils/displayUtils");

const DECLARATION_TYPE_CHOICE_ELEMENT_ID = 'declaration-type-choice';
const ONLINE_DECLARATION_TYPE_RADIO_ID = 'declaration-type--online';

function hide() {
    displayUtils.hide(document.getElementById(DECLARATION_TYPE_CHOICE_ELEMENT_ID));
}

function show() {
    displayUtils.show(document.getElementById(DECLARATION_TYPE_CHOICE_ELEMENT_ID));
}

function uncheckOnlineDeclarationType() {
    document.getElementById(ONLINE_DECLARATION_TYPE_RADIO_ID).checked = false;
}

function scrollTo() {
    document.getElementById('declaration-type-choice').scrollIntoView({
        behavior: "smooth"
    });
}

function initEvents() {
    document.getElementById(ONLINE_DECLARATION_TYPE_RADIO_ID).addEventListener('change', e => {
        paymentPromise.show();
        paymentPromise.scrollToSiret();
    });
}

module.exports = {
    initEvents,
    show,
    hide,
    uncheckOnlineDeclarationType,
    scrollTo
};