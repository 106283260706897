const requestHelper = require('./requestHelper');

module.exports = (paymentPromiseId, contactMode) => {
    return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();

        req.addEventListener('load', function() {
            requestHelper.routePromise(req, resolve, reject);
        });

        req.addEventListener('error', function() {
            reject(req);
        });

        req.open('POST',  window.location.origin + '/api/payment/promise/' + paymentPromiseId + '/contact-me');

        req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        req.send(JSON.stringify({
            contactMode
        }));
    });
};