const declarationTypeChoice = require('./components/declarationTypeChoice');
const schoolChoice = require('./components/schoolChoice');
const campusChoice = require('./components/inseec/campusChoice');
const paymentPromise = require('./components/paymentPromise/paymentPromise');
const displayUtils = require('../utils/displayUtils');
const soutien = require('./components/soutien');
const contact = require('./components/contact');
const sendContactMeNotification = require('../../api/sendContactMeNotification');

const DOMAIN_CODE_ELEMENT_ID = 'domain-code';
const INSEEC_PARIS_SCHOOL = 'inseec-paris';
const INSEEC_LYON_SCHOOL = 'inseec-lyon';
const INSEEC_BORDEAUX_SCHOOL = 'inseec-bordeaux';
const OMNES_GROUP = 'omnes';

const TECHNICAL_SHEET_CLASS_NAME = 'technical-sheet';
const INFORMATIONAL_TECHNICAL_SHEET_ELEMENT_ID = 'informational-technical-sheet';

const CONFIRMATION_MODAL_ELEMENT_ID = 'payment-promise-confirmed-modal';
const CLOSE_CONFIRMATION_MODAL_BUTTON_ID = 'close-payment-promise-confirmed-modal';

const SEND_CONTACT_BY_EMAIL_NOTIFICATION_BUTTON_ID = 'contact-by-email';
const SEND_CONTACT_BY_PHONE_NOTIFICATION_BUTTON_ID = 'contact-by-phone';

const TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL_ELEMENT_ID = "tax-amount-reversible-calculator-modal";
const CLOSE_TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL = "close-tax-amount-reversible-calculator-modal";
const OPEN_TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL = "open-tax-amount-reversible-calculator-modal";
const TOTAL_TAX_AMOUNT_BALANCE_INPUT_ID = 'total-tax-amount-balance';
const WISH_TAX_AMOUNT_INPUT_ID = 'wish-tax-amount';
const WISH_TAX_PCT_INPUT_ID = 'wish-tax-pct';


function getDomainCode() {
    return document.getElementById(DOMAIN_CODE_ELEMENT_ID).innerText;
}

function showInformationalTechnicalSheet() {
    displayUtils.show(document.getElementById(INFORMATIONAL_TECHNICAL_SHEET_ELEMENT_ID));
}

function hideInformationTechnicalSheet() {
    displayUtils.hide(document.getElementById(INFORMATIONAL_TECHNICAL_SHEET_ELEMENT_ID));
}

function openConfirmationModal() {
    document.getElementById(CONFIRMATION_MODAL_ELEMENT_ID).classList.add('open');
}

function closeConfirmationModal() {
    document.getElementById(CONFIRMATION_MODAL_ELEMENT_ID).classList.remove('open');
}

function getSchoolCode() {
    const domainCode = getDomainCode();

    // check if we are on omnes group page
    if(domainCode === OMNES_GROUP) {
        const selectedSchool = schoolChoice.getSelectedSchool();

        if(schoolChoice.INSEEC_GROUP === selectedSchool) {
            const selectedCampus = campusChoice.getSelectedCampus();

            switch(selectedCampus) {
                case campusChoice.ILE_DE_FRANCE_CAMPUS:
                    return INSEEC_PARIS_SCHOOL;
                case campusChoice.NOUVELLE_AQUITAINE_CAMPUS:
                    return INSEEC_BORDEAUX_SCHOOL;
                case campusChoice.AUVERGNE_RHONE_ALPES_CAMPUS:
                    return INSEEC_LYON_SCHOOL;
                default:
                    return null;
            }
        }

        return selectedSchool;
    }

    if(domainCode === schoolChoice.INSEEC_GROUP) {
        const selectedCampus = campusChoice.getSelectedCampus();

        switch(selectedCampus) {
            case campusChoice.ILE_DE_FRANCE_CAMPUS:
                return INSEEC_PARIS_SCHOOL;
            case campusChoice.NOUVELLE_AQUITAINE_CAMPUS:
                return INSEEC_BORDEAUX_SCHOOL;
            case campusChoice.AUVERGNE_RHONE_ALPES_CAMPUS:
                return INSEEC_LYON_SCHOOL;
            default:
                return null;
        }
    }

    return domainCode;
}

/**
 * Get bank account asset relative path.
 *
 * @param schoolCode
 * @returns {string}
 */
function getBankAccountIdentityAssetRelPath(schoolCode) {
    let relPath = window.location.href;

    switch(schoolCode) {
        case INSEEC_PARIS_SCHOOL:
            relPath += 'medias/pdf/bank_account_identity/RIB_INSEEC_PARIS_TAXE_APPRENTISSAGE_2022.pdf';
            break;
        case INSEEC_LYON_SCHOOL:
            relPath += 'medias/pdf/bank_account_identity/RIB_INSEEC_LYON_TAXE_APPRENTISSAGE_2022.pdf';
            break;
        case INSEEC_BORDEAUX_SCHOOL:
            relPath += 'medias/pdf/bank_account_identity/RIB_INSEEC_BORDEAUX_TAXE_APPRENTISSAGE_2022.pdf';
            break;
        case schoolChoice.ECE_SCHOOL:
            relPath += 'medias/pdf/bank_account_identity/RIB_ECE_TAXE_APPRENTISSAGE_2022.pdf';
            break;
        case schoolChoice.EBS_SCHOOL:
            relPath += 'medias/pdf/bank_account_identity/RIB_EBS_TAXE_APPRENTISSAGE_2022.pdf';
            break;
        default:
    }

    return relPath;
}

/**
 * Get technical sheet asset relative path.
 *
 * @param schoolCode
 * @returns {string}
 */
function getTechnicalSheetAssetRelPath(schoolCode) {
    let relPath = window.location.href;

    switch(schoolCode) {
        case INSEEC_PARIS_SCHOOL:
            relPath += 'medias/pdf/technical_sheet/INSEEC_PARIS_FicheTechnique_TA2022.pdf';
            break;
        case INSEEC_LYON_SCHOOL:
            relPath += 'medias/pdf/technical_sheet/INSEEC_LYON_FicheTechnique_TA2022.pdf';
            break;
        case INSEEC_BORDEAUX_SCHOOL:
            relPath += 'medias/pdf/technical_sheet/INSEEC_BORDEAUX_FicheTechnique_TA2022.pdf';
            break;
        case schoolChoice.ECE_SCHOOL:
            relPath += 'medias/pdf/technical_sheet/ECE_FicheTechnique_TA2022.pdf';
            break;
        case schoolChoice.EBS_SCHOOL:
            relPath += 'medias/pdf/technical_sheet/EBS_FicheTechnique_TA2022.pdf';
            break;
        default:
    }

    return relPath;
}

function getPaperFormAssetRelPath(schoolCode) {
    let relPath = window.location.origin + '/';

    switch(schoolCode) {
        case INSEEC_PARIS_SCHOOL:
            relPath += 'medias/pdf/paper_form/INSEEC_PARIS_Formulaire_TaxeApprentissage22.pdf';
            break;
        case INSEEC_LYON_SCHOOL:
            relPath += 'medias/pdf/paper_form/INSEEC_LYON_Formulaire_TaxeApprentissage22.pdf';
            break;
        case INSEEC_BORDEAUX_SCHOOL:
            relPath += 'medias/pdf/paper_form/INSEEC_BORDEAUX_Formulaire_TaxeApprentissage22.pdf';
            break;
        case schoolChoice.ECE_SCHOOL:
            relPath += 'medias/pdf/paper_form/ECE_Formulaire_TaxeApprentissage22.pdf';
            break;
        case schoolChoice.EBS_SCHOOL:
            relPath += 'medias/pdf/paper_form/EBS_Formulaire_TaxeApprentissage22.pdf';
            break;
        default:
    }

    return relPath;
}

function setTechnicalSheetAssetRelPath() {
    const technicalSheetRelPath = getTechnicalSheetAssetRelPath(getSchoolCode());

    const elements = document.getElementsByClassName(TECHNICAL_SHEET_CLASS_NAME);
    for(let element of elements) {
        element.href = technicalSheetRelPath;
    }
}

function clearReversibleCalculatorFields() {
    document.getElementById(WISH_TAX_PCT_INPUT_ID).value = null;
    document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).value = null;
}

function initEvents() {
    schoolChoice.initEvents({
        setSchoolName: paymentPromise.setSchoolName,
        setTechnicalSheetAssetRelPath,
        hideInformationTechnicalSheet,
        showInformationalTechnicalSheet,
        scrollToCampusChoice: campusChoice.scrollTo,
        scrollToDeclarationTypeChoice: declarationTypeChoice.scrollTo
    });
    campusChoice.initEvents({
        setSchoolName: paymentPromise.setSchoolName,
        setTechnicalSheetAssetRelPath,
        showInformationalTechnicalSheet,
        showOnlyContact: contact.showOnly,
        scrollToDeclarationTypeChoice: declarationTypeChoice.scrollTo
    });
    declarationTypeChoice.initEvents();
    paymentPromise.initEvents({
        getSchoolCode,
        disableSchoolChoiceFields: schoolChoice.disableFields,
        disableCampusChoiceFields: campusChoice.disableFields,
        openConfirmationModal,
        setTotalTaxAmountBalance,
        clearReversibleCalculatorFields
    });
    soutien.initEvents();

    document.getElementById(CLOSE_CONFIRMATION_MODAL_BUTTON_ID).addEventListener('click', e => {
        e.preventDefault();
        closeConfirmationModal();
    });

    document.getElementById(SEND_CONTACT_BY_EMAIL_NOTIFICATION_BUTTON_ID).addEventListener('click', e => {
        e.preventDefault();

        if(!confirm('Etes-vous sûr(e) de vouloir être recontacté(e) par email ?')) {
            return;
        }

        closeConfirmationModal();
        const paymentPromiseId = localStorage.getItem(paymentPromise.getPaymentPromiseIdStorageKey());
        sendContactMeNotification(paymentPromiseId, 'EMAIL')
            .then(request => {
                alert('Demande de contact envoyée avec succès.');
            })
            .catch(request => {
                console.error(request);
            });
    });

    document.getElementById(SEND_CONTACT_BY_PHONE_NOTIFICATION_BUTTON_ID).addEventListener('click', e => {
        e.preventDefault();

        if(!confirm('Etes-vous sûr(e) de vouloir être recontacté(e) par téléphone ?')) {
            return;
        }

        closeConfirmationModal();
        const paymentPromiseId = localStorage.getItem(paymentPromise.getPaymentPromiseIdStorageKey());
        sendContactMeNotification(paymentPromiseId, 'PHONE')
            .then(request => {
                alert('Demande de contact envoyée avec succès.');
            })
            .catch(request => {
                console.error(request);
            });
    });

    // REVERSIBLE CALCULATOR
    document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).addEventListener('input', e => {
        let amount = e.target.value;

        if(!e.target.reportValidity()) {
            document.getElementById(WISH_TAX_PCT_INPUT_ID).value = null;
            return;
        }

        if(0 === amount) {
            document.getElementById(WISH_TAX_PCT_INPUT_ID).value = 0;

            return;
        }

        if(null === amount) {
            document.getElementById(WISH_TAX_PCT_INPUT_ID).value = null;

            return;
        }

        if(amount < 0) {
            amount = 0 - amount;
        }

        document.getElementById(WISH_TAX_PCT_INPUT_ID).value = calculateTaxPercentage(amount);
    });

    document.getElementById(WISH_TAX_PCT_INPUT_ID).addEventListener('input', e => {
        let percentage = e.target.value;

        if(!e.target.reportValidity()) {
            document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).value = null;
            return;
        }

        if(0 === percentage) {
            document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).value = 0;

            return;
        }

        if(null === percentage) {
            document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).value = null;

            return;
        }

        if(percentage < 0) {
            percentage = 0 - percentage;
        }

        document.getElementById(WISH_TAX_AMOUNT_INPUT_ID).value = calculateTaxAmount(percentage);
    });

    // if total tax amount balance is modified, we clean amount and percentage
    document.getElementById(TOTAL_TAX_AMOUNT_BALANCE_INPUT_ID).addEventListener('input', e => {
        clearReversibleCalculatorFields();
    });

    document.getElementById(CLOSE_TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL).addEventListener('click', e => {
        e.preventDefault();

        closeReversibleCalculator();
    });

    document.getElementById(OPEN_TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL).addEventListener('click', e => {
        e.preventDefault();

        openReversibleCalculator();
    });
}

function openReversibleCalculator() {
    document.getElementById(TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL_ELEMENT_ID).classList.add("open");
}

function closeReversibleCalculator() {
    document.getElementById(TAX_AMOUNT_REVERSIBLE_CALCULATOR_MODAL_ELEMENT_ID).classList.remove("open");
}

function setTotalTaxAmountBalance(amount) {
    document.getElementById(TOTAL_TAX_AMOUNT_BALANCE_INPUT_ID).value = amount;
}

function getTotalTaxAmountBalance() {
    return Number(document.getElementById(TOTAL_TAX_AMOUNT_BALANCE_INPUT_ID).value).toFixed(2);
}

function calculateTaxPercentage(amount) {
    const totalTaxAmountBalance = getTotalTaxAmountBalance();

    return Number( amount / totalTaxAmountBalance * 100).toFixed(2);
}

function calculateTaxAmount(percentage) {
    const totalTaxAmountBalance = getTotalTaxAmountBalance();

    return Number(totalTaxAmountBalance * percentage / 100).toFixed(2);
}

module.exports = {
    initEvents,
    getSchoolCode,
    OMNES_GROUP,
    getDomainCode
}