const displayUtils = require('../../../utils/displayUtils');

const RECEIPT_RECIPIENTS_WRAPPER_ELEMENT_ID = 'contacts';
const REMOVE_CONTACT_CLASS_NAME = 'remove-contact';
const CONTACT_CLASS_NAME = 'contact';
const ADD_ELEMENT_ID = 'add-contact';

const RECEIPT_RECIPIENT_IS_DECISION_MAKER_CHECKBOX_ID = 'contact';

const INDEX_PATTERN = '_index_';
const RECEIPT_RECIPIENT_ELEMENT_ID_PATTERN = 'contact_' + INDEX_PATTERN;

const RECEIPT_RECIPIENT_MADAM_TITLE_RADIO_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_madame';
const RECEIPT_RECIPIENT_MISTER_TITLE_RADIO_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_monsieur';
const RECEIPT_RECIPIENT_LAST_NAME_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_name';
const RECEIPT_RECIPIENT_FIRST_NAME_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_forname';
const RECEIPT_RECIPIENT_EMAIL_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_email';
const RECEIPT_RECIPIENT_PHONE_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_phone';
const RECEIPT_RECIPIENT_FUNCTION_INPUT_ID_PATTERN = 'contact_other_' + INDEX_PATTERN + '_function';

const MISTER = 'MISTER';
const MADAM = 'MADAME';

function isVisible(index) {
    return displayUtils.isVisible(document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_ELEMENT_ID_PATTERN, index)));
}

/**
 * Get values.
 *
 * @param index
 * @returns {{person}}
 */
function getValues(index) {
    let receiptRecipient = {};
    const radioReceiptRecipientMadam = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_MADAM_TITLE_RADIO_ID_PATTERN, index));
    const radioReceiptRecipientMister = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_MISTER_TITLE_RADIO_ID_PATTERN, index));
    let receiptRecipientTitle = null;
    if(radioReceiptRecipientMadam.checked) {
        receiptRecipientTitle = radioReceiptRecipientMadam.value;
    } else if(radioReceiptRecipientMister.checked) {
        receiptRecipientTitle = radioReceiptRecipientMister.value;
    }
    receiptRecipient['title'] = receiptRecipientTitle;
    receiptRecipient['lastName'] = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_LAST_NAME_INPUT_ID_PATTERN, index)).value;
    receiptRecipient['firstName'] = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_FIRST_NAME_INPUT_ID_PATTERN, index)).value;
    receiptRecipient['function'] = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_FUNCTION_INPUT_ID_PATTERN, index)).value;
    receiptRecipient['email'] = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_EMAIL_INPUT_ID_PATTERN, index)).value;
    receiptRecipient['phoneNumber'] = document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_PHONE_INPUT_ID_PATTERN, index)).value;

    return { person: receiptRecipient };
}

function resolvePatternWithIndex(pattern, index) {
    return pattern.replace(INDEX_PATTERN, index);
}

function setReceiptRecipientTitle(index, titleValue) {
    if(MADAM === titleValue) {
        document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_MADAM_TITLE_RADIO_ID_PATTERN, index)).checked = true;

        return;
    }

    if(MISTER === titleValue) {
        document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_MISTER_TITLE_RADIO_ID_PATTERN, index)).checked = true;
    }
}

function populateReceiptRecipient(index, information) {
    setReceiptRecipientTitle(index, information.title);
    document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_LAST_NAME_INPUT_ID_PATTERN, index)).value = information.lastName;
    document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_FIRST_NAME_INPUT_ID_PATTERN, index)).value = information.firstName;
    document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_EMAIL_INPUT_ID_PATTERN, index)).value = information.email;
    document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_PHONE_INPUT_ID_PATTERN, index)).value = information.phone;
    document.getElementById(resolvePatternWithIndex(RECEIPT_RECIPIENT_FUNCTION_INPUT_ID_PATTERN, index)).value = information.jobFunction;
}

function emptyReceiptRecipientInformation(index) {
    populateReceiptRecipient(index, {
        title: null,
        lastName: '',
        firstName: '',
        email: '',
        phone: '',
        jobFunction: ''
    });
}

function getFirstInvisibleReceiptRecipient() {
    const contacts = document.getElementsByClassName(CONTACT_CLASS_NAME);

    for(let contact of contacts) {
        if(!displayUtils.isVisible(contact)) {
            return contact;
        }
    }

    return null;
}

function hideReceiptRecipients() {
    displayUtils.hide(document.getElementById(RECEIPT_RECIPIENTS_WRAPPER_ELEMENT_ID));
}

function showReceiptRecipients() {
    displayUtils.show(document.getElementById(RECEIPT_RECIPIENTS_WRAPPER_ELEMENT_ID));
}

function initEvents(args) {
    // manage receipt recipients fields visibility
    document.getElementById(ADD_ELEMENT_ID).addEventListener('click', function(e) {
        e.preventDefault();

       const firstInvisibleReceiptRecipient = getFirstInvisibleReceiptRecipient();
       if(!firstInvisibleReceiptRecipient) {
           return;
       }

       displayUtils.show(firstInvisibleReceiptRecipient);
    });

    // add event listener on delete button
    const removeQuerySelector = '.' + REMOVE_CONTACT_CLASS_NAME;
    document.querySelectorAll(removeQuerySelector).forEach(item => {
        item.addEventListener('click', function(e) {
            const target = e.target;

            displayUtils.hide(target.parentElement);
        });
    });

    document.getElementById(RECEIPT_RECIPIENT_IS_DECISION_MAKER_CHECKBOX_ID).addEventListener('change',e => {
        if(!e.target.checked) {
            showReceiptRecipients();

            return;
        }

        emptyReceiptRecipientInformation(1);
        emptyReceiptRecipientInformation(2);
        emptyReceiptRecipientInformation(3);
        hideReceiptRecipients();
    });
}

module.exports = {
    getValues,
    isVisible,
    initEvents
};