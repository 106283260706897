const displayUtils = require('../../utils/displayUtils');

const INSEEC_PARIS_CONTACT_ELEMENT_ID = 'contact-inseec-paris';
const INSEEC_BORDEAUX_CONTACT_ELEMENT_ID = 'contact-inseec-bordeaux';
const INSEEC_LYON_CONTACT_ELEMENT_ID = 'contact-inseec-lyon';

const INSEEC_PARIS_VALUE = 'inseec-paris';
const INSEEC_BORDEAUX_VALUE = 'inseec-bordeaux';
const INSEEC_LYON_VALUE = 'inseec-lyon';

function showOnly(schoolValue) {
    if(!document.getElementById(INSEEC_PARIS_CONTACT_ELEMENT_ID)) {
        return;
    }

    switch(schoolValue) {
        case INSEEC_PARIS_VALUE:
            displayUtils.show(document.getElementById(INSEEC_PARIS_CONTACT_ELEMENT_ID));
            displayUtils.hide(document.getElementById(INSEEC_BORDEAUX_CONTACT_ELEMENT_ID));
            displayUtils.hide(document.getElementById(INSEEC_LYON_CONTACT_ELEMENT_ID));
            break;
        case INSEEC_BORDEAUX_VALUE:
            displayUtils.hide(document.getElementById(INSEEC_PARIS_CONTACT_ELEMENT_ID));
            displayUtils.show(document.getElementById(INSEEC_BORDEAUX_CONTACT_ELEMENT_ID));
            displayUtils.hide(document.getElementById(INSEEC_LYON_CONTACT_ELEMENT_ID));
            break;
        case INSEEC_LYON_VALUE:
            displayUtils.hide(document.getElementById(INSEEC_PARIS_CONTACT_ELEMENT_ID));
            displayUtils.hide(document.getElementById(INSEEC_BORDEAUX_CONTACT_ELEMENT_ID));
            displayUtils.show(document.getElementById(INSEEC_LYON_CONTACT_ELEMENT_ID));
            break;
    }
}

module.exports = {
    showOnly
}